import "./styles.css";
import landingpage from "./svgSlides/landingpage";
import { parseHTML, getParam } from "../shared/services";
import data from "../shared/de_org.json";

let strings;
if (window.allTranslatedTexts) {
  strings = window.allTranslatedTexts;
} else {
  strings = data.strings;
}

let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
svg.setAttribute("width", "100%");
svg.setAttribute("height", "100%");
svg.setAttributeNS("http://www.w3.org/2000/xmlns/", "xmlns:xlink", "http://www.w3.org/1999/xlink");
svg.setAttribute("viewBox", "0 0 3000 4500");
svg.innerHTML = landingpage;
document.querySelector(".landing-right-side").appendChild(svg);

for (let i = 0; i < strings.length; i++) {
  if (strings[i].key === "LANDING_SIGNPOST") {
    document.querySelector("#signpost_label").textContent = parseHTML(strings[i].value);
  } else if (strings[i].key === "LANDING_HEADING") {
    document.querySelector(".landing-heading h3").textContent = parseHTML(strings[i].value);
  } else if (strings[i].key === "LANDING_DESC") {
    document.querySelector(".landing-desc").innerHTML = strings[i].value;
  } else if (strings[i].key === "LANDING_BUTTON_GUIDED_TOUR") {
    document.querySelector(".guided-link").textContent = parseHTML(strings[i].value);
  } else if (strings[i].key === "LANDING_BUTTON_FREE_TOUR") {
    document.querySelector(".free-link").textContent = parseHTML(strings[i].value);
  }
}
const links = Array.from(document.querySelectorAll(".dynamicLink"));
const lang = getParam(window.location.search, links);

var DOMReady = function(callback) {
  document.readyState === "interactive" || document.readyState === "complete" ? callback() : document.addEventListener("DOMContentLoaded", callback);
};
DOMReady(function() {
    document.body.style.opacity = "1";
});
