//parseHTML
export function parseHTML(html) {
  let div = document.createElement("div");
  div.innerHTML = html;
  return div.textContent || div.innerText || "";
}
//form the strings to compare
export function formStrings(numri) {
  let tempStr = "";
  for (let i = 0; i < numri.length; i++) {
    tempStr += `_${numri[i]}`;
  }
  return [`HEADING${tempStr}`, `SHORT_DESCRIPTION${tempStr}`, `LONG_DESCRIPTION${tempStr}`, `BUTTON_LINK${tempStr}`];
}

//Extract numbers
export function extractNum(text) {
  return text.replace(/\D/g, "");
}

//Extract language parameter
export function getParam(url, links) {
  const urlParams = new URLSearchParams(url);
  const param = urlParams.get("language");
  links.forEach(function (link) {
    if (param) link.href = `${link.href}?language=${param}`;
  });
}
